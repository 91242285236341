document.addEventListener('DOMContentLoaded',function(){
    iniciarApp();
})

function iniciarApp(){
    navegacionFija();
    scrollNav();
}
function navegacionFija(){
    const barra = document.querySelector('.header'); 
    const sobreFestival= document.querySelector('.sobre-festival');
    const body = document.querySelector('body');
    window.addEventListener('scroll', function(){
       // console.log(sobreFestival);
        if(sobreFestival.getBoundingClientRect().top<0){
            //console.log('Ya pasamos el elemento');
            barra.classList.add('fijo');
            body.classList.add('alto-header');
        } else {
            //console.log('Aún no...');
            barra.classList.remove('fijo');
            body.classList.remove('alto-header');
        }
    })
}
function scrollNav(){
    console.log('Inico de scrollNav');
    const enlaces = document.querySelectorAll('.navegacion-principal a');
    console.log(enlaces);
    enlaces.forEach( enlaces => {
        enlaces.addEventListener('click', function(e){
            e.preventDefault();
            const seccionScroll = e.target.attributes.href.value;
            console.log(seccionScroll);
            const seccion = document.querySelector(seccionScroll);
            seccion.scrollIntoView({behavior:"smooth"});
        })
    });
}

function mostrarImagen(i){
    const imagen = document.createElement('picture');
    const div = document.createElement('div');
    const cerrarModal = document.createElement('p');
    const body = document.querySelector('body');
    div.className = 'overlay';
    
    // boton cerra modal
    cerrarModal.onclick = function(){
        const body = document.querySelector('body');
        div.remove();
        body.classList.remove('fijar-body');
    }
    
    div.onclick = function(){
        const body = document.querySelector('body');
        div.remove();
        body.classList.remove('fijar-body');
    }

    imagen.innerHTML = `
    <source srcset="build/img/grande/${i}.avif" type="image/avif">
    <source srcset="build/img/grande/${i}.webp" type="image/webp">
    <img loading="lazy" width="200" height="300" src="build/img/grande/${i}.jpg" alt="imagen">
    `;

    // cerrar modal
    cerrarModal.classList.add('boton-cerrar');
    cerrarModal.textContent = 'X';
    div.appendChild(imagen);
    div.appendChild(cerrarModal);
    body.appendChild(div);
    body.classList.add('fijar-body');
}
// Validar formulario
const formulario = document.querySelector('.infusion-form');

formulario.addEventListener('submit',function(evento){
    evento.preventDefault();
    // quitar la clase vacio
    //console.log(evento);
    //console.log(evento.id);
    // Validar datos
    const inf_field_FirstName = document.querySelector('#inf_field_FirstName');
    const inf_field_Email = document.querySelector('#inf_field_Email');
    const inf_custom_paisyciudad = document.querySelector('#inf_custom_paisyciudad');
    const inf_custom_Profesioneslocutores = document.querySelector('#inf_custom_Profesioneslocutores');
    const mensajeError = document.querySelector('#mensajeError');
    mensajeError.textContent= '';
    //console.log(inf_custom_Profesioneslocutores);
    //console.log('Select: '+inf_custom_Profesioneslocutores.value);
    inf_field_FirstName.classList.remove('vacio');
    inf_field_Email.classList.remove('vacio');
    inf_custom_paisyciudad.classList.remove('vacio');
    // Validar
    if(inf_field_FirstName.value === '') { errorMensaje(inf_field_FirstName); return;}
    if(inf_field_Email.value === '') { errorMensaje(inf_field_Email); return;}
    if(ValidaEmail(inf_field_Email.value) === false) { errorMensaje(inf_field_Email); return;}
    if(inf_custom_paisyciudad.value === '') { errorMensaje(inf_custom_paisyciudad); return;}
    // Lista
    if(inf_custom_Profesioneslocutores.value === '') {
        inf_custom_Profesioneslocutores.focus();
        mensajeError.innerHTML = '<p>Selecciona una opción</p>';
        return;
    }
    mensajeError.innerHTML = '<p>Un momento, se esta procesando...</p>';
    // llamar un json
    const url = 'https://emprendeloqueamas.com/php/altaInfusionSoft.php?inf_field_FirstName='+inf_field_FirstName.value+'&inf_field_Email='+inf_field_Email.value+'&inf_custom_paisyciudad='+inf_custom_paisyciudad.value+'&inf_custom_Profesioneslocutores='+inf_custom_Profesioneslocutores.value;

    fetch(url)
    .then((resp) => resp.json())
    .then(function(data) {
        console.log(data);
        if(data.id>0){
            // redicionar
            window.location.href = "https://emprendeloqueamas.com/gracias/";
        } else {
            mensajeError.innerHTML = '<p>Hubo un error envinos un WhatsApp: </p>';
        }
    })
    .catch(function(error) {
        console.log(error);
    });
    console.log('Enviar submit');
});





function errorMensaje(campo){
    const mensajeError = document.querySelector('#mensajeError');
    mensajeError.textContent = '';
    const p = document.createElement('P');

    //console.log(mensajeError);
    campo.focus();
    campo.classList.add('vacio')
    //console.log('El campo es obligatorio');
   // console.log(campo.id);
    const mensaje = 'Ingresa: '+ campo.placeholder;
    p.textContent= mensaje;
    //mensajeError.textContent = mensajeError;
    mensajeError.append(p);
    console.log(mensaje);
}
function ValidaEmail(email) {
    var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email);
}
